/* Browser reset */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
.logos,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

.logos,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

textarea,
input.text,
input[type='text'],
input[type='button'],
input[type='submit'],
.input-checkbox {
    -webkit-appearance: none;
    border-radius: 0;
}

button {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes scaleIn {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

body {
    background-color: #264653;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
}

main {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: auto;
    min-height: 100vh;
}

section {
    display: flex;
    justify-content: center;
    align-items: center;
}

h1 {
    font-size: 4rem;
    letter-spacing: 2px;
    line-height: 5rem;
}

section img {
    transform: scaleX(0);
    width: 100%;
    height: 100vh;
    object-fit: cover;
    animation: scaleIn 1.25s cubic-bezier(0.4, 0, 0.2, 1) 1.5s 1 forwards;
}

@keyframes translateLeft {
    from {
        transform: translateX(20vw);
    }

    to {
        transform: translateX(0);
    }
}

section:first-of-type {
    transform: translateX(20vw);
    opacity: 0;
    flex-direction: column;
    width: auto;
    margin: 0 auto;
    padding: 2rem;
    box-sizing: content-box;
    animation: fadeIn 1.5s cubic-bezier(0.4, 0, 0.2, 1) 0.5s 1 forwards,
        translateLeft 1.25s cubic-bezier(0.4, 0, 0.2, 1) 1.5s 1 forwards;
}

section:first-of-type h1:after {
    content: '';
    height: 100px;
    width: 100px;
    background-color: red;
}

.logos {
    display: flex;
    margin-top: 3rem;
    justify-content: space-between;
    width: 100%;
    position: relative;
}

.logos:nth-of-type(2) {
    margin-top: 1rem;
    background-color: #47829a;
    padding: 1rem;
    box-sizing: content-box;
    border-radius: 15px;
    box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.15);
}

.logo a,
.logo svg {
    transition: ease 0.2s;
    filter: brightness(1);
    transform: translate3d(0,0,0);
}

.logo:hover a,
.logo:hover svg {
    filter: brightness(1.2);
}

.logo a {
    display: flex;
    color: #47829a;
    text-decoration: none;
    font-size: 2.5rem;
    height: 100%;
    width: 3rem;
    justify-content: center;
}

.logo button {
    border: none;
    background: none;
    cursor: pointer;
    margin: 0;
    padding: 0;

}

.logo svg {
    transition: ease 0.2s;
    height: 3rem;
    color: #47829a;
    stroke: #47829a;
}

.logo svg g {
    transform-origin: 50%;
    transform: scale(1.3);
}

section .logos:nth-of-type(2) .logo:last-of-type {
    transform: scale(0.9);
}

.toggle-audio {
    position: absolute;
    top: 0.75rem;
    left: 0.5rem;
    background: none;
    border: none;
    color: #47829a;
    font-size: 2rem;
    display: flex;
    justify-content: flex-start;
    width: 3.5rem;
    outline: none;
    opacity: 0.25;
}

.toggle-audio:hover {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    main {
        min-height: 90vh;
        display: flex;
        grid-template-columns: none;
        grid-template-rows: none;
        align-items: center;
    }

    section img {
        display: none;
    }

    section h1 {
        margin-top: 1.8rem;
    }

    section:first-of-type {
        transform: none;
        animation: fadeIn 1.5s ease 0.5s 1 forwards;
    }

    section:first-of-type .logos {
        margin-top: 2rem;
    }

    .toggle-audio {
        font-size: 2rem;
        top: 0.5rem;
        left: -1rem;
        width: 4.5rem;
    }
}
