html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, .logos, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

.logos, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

textarea, input.text, input[type="text"], input[type="button"], input[type="submit"], .input-checkbox {
  -webkit-appearance: none;
  border-radius: 0;
}

button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes scaleIn {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

body {
  color: #fff;
  background-color: #264653;
  font-family: Open Sans, sans-serif;
}

main {
  min-height: 100vh;
  grid-template-rows: auto;
  grid-template-columns: 3fr 2fr;
  display: grid;
}

section {
  justify-content: center;
  align-items: center;
  display: flex;
}

h1 {
  letter-spacing: 2px;
  font-size: 4rem;
  line-height: 5rem;
}

section img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  animation: 1.25s cubic-bezier(.4, 0, .2, 1) 1.5s forwards scaleIn;
  transform: scaleX(0);
}

@keyframes translateLeft {
  from {
    transform: translateX(20vw);
  }

  to {
    transform: translateX(0);
  }
}

section:first-of-type {
  opacity: 0;
  width: auto;
  box-sizing: content-box;
  flex-direction: column;
  margin: 0 auto;
  padding: 2rem;
  animation: 1.5s cubic-bezier(.4, 0, .2, 1) .5s forwards fadeIn, 1.25s cubic-bezier(.4, 0, .2, 1) 1.5s forwards translateLeft;
  transform: translateX(20vw);
}

section:first-of-type h1:after {
  content: "";
  height: 100px;
  width: 100px;
  background-color: red;
}

.logos {
  width: 100%;
  justify-content: space-between;
  margin-top: 3rem;
  display: flex;
  position: relative;
}

.logos:nth-of-type(2) {
  box-sizing: content-box;
  background-color: #47829a;
  border-radius: 15px;
  margin-top: 1rem;
  padding: 1rem;
  box-shadow: 3px 5px 20px #00000026;
}

.logo a, .logo svg {
  filter: brightness();
  transition: all .2s;
  transform: translate3d(0, 0, 0);
}

.logo:hover a, .logo:hover svg {
  filter: brightness(1.2);
}

.logo a {
  color: #47829a;
  height: 100%;
  width: 3rem;
  justify-content: center;
  font-size: 2.5rem;
  text-decoration: none;
  display: flex;
}

.logo button {
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.logo svg {
  height: 3rem;
  color: #47829a;
  stroke: #47829a;
  transition: all .2s;
}

.logo svg g {
  transform-origin: 50%;
  transform: scale(1.3);
}

section .logos:nth-of-type(2) .logo:last-of-type {
  transform: scale(.9);
}

.toggle-audio {
  color: #47829a;
  width: 3.5rem;
  opacity: .25;
  background: none;
  border: none;
  outline: none;
  justify-content: flex-start;
  font-size: 2rem;
  display: flex;
  position: absolute;
  top: .75rem;
  left: .5rem;
}

.toggle-audio:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  main {
    min-height: 90vh;
    grid-template-rows: none;
    grid-template-columns: none;
    align-items: center;
    display: flex;
  }

  section img {
    display: none;
  }

  section h1 {
    margin-top: 1.8rem;
  }

  section:first-of-type {
    animation: 1.5s .5s forwards fadeIn;
    transform: none;
  }

  section:first-of-type .logos {
    margin-top: 2rem;
  }

  .toggle-audio {
    width: 4.5rem;
    font-size: 2rem;
    top: .5rem;
    left: -1rem;
  }
}

/*# sourceMappingURL=index.ad569d70.css.map */
